import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutTwo } from "../../layouts";
import { BannerSix } from "../../components/Banner";
import { ServiceCardOne } from "../../components/ServiceCard";
import { FeatureSix } from "../../components/Features";

const HomePageSix = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="iKEP - Find the nearest Citizen Service Center (KEP) quickly with iKEP! Over 1000 KEPs in your hand. Try it now!"
        favicon="assets/images/ikep/ikep-fav-icon.png"
      />
      <LayoutTwo
        activeClass="active"
        wrapperClass="home-page-6"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="black-content"
      >
        <main>
          <BannerSix sectionSpace="mb-60" />
          <ServiceCardOne sectionSpace="mb-100" />
          <FeatureSix sectionSpace="sec-ptb-160 pt-0" />
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default HomePageSix;
