import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import PrivacyPolicyData from "../../data/privacyPolicy/privacy-policy.json";

const [
  {
    title, effectiveDate, intro, title1, title2, title3, title4, title5,
    title6, title7, title8, title9, paragraph1, paragraph2, paragraph3, paragraph4,
    paragraph5, paragraph6, paragraph7, paragraph8, paragraph9, usage, thanks, },
] = PrivacyPolicyData;
const PrivacyPolicy = ({ sectionSpace }) => {
  return (
    <Fragment>
      <section
        id="about-section"
        className={`about-section ${sectionSpace} clearfix`}
      >
        <Container>
          <Row className="justify-content-lg-between justify-content-md-center">
            <Col lg="12" md="12" xs="12">
              <div className="about-content">
                <h2 className="title-text mb-45">
                  {title}
                </h2>
                <p className="mb-30">{effectiveDate}</p>
                <p className="mb-30">{intro}</p>
                <h4>{title1}</h4>
                <p className="mb-60">{paragraph1}</p>
                <h4>{title2}</h4>
                <p className="mb-60">{paragraph2}</p>
                <h4>{title3}</h4>
                <p className="mb-60">{paragraph3}</p>
                <h4>{title4}</h4>
                <p className="mb-60">{paragraph4}</p>
                <h4>{title5}</h4>
                <p className="mb-60">{paragraph5}</p>
                <h4>{title6}</h4>
                <p className="mb-60">{paragraph6}</p>
                <h4>{title7}</h4>
                <p className="mb-60">{paragraph7}</p>
                <h4>{title8}</h4>
                <p className="mb-60">{paragraph8}</p>
                <h4>{title9}</h4>
                <p className="mb-60">{paragraph9}</p>
                <p className="mb-60">{usage}</p>
                <p className="mb-60">{thanks}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default PrivacyPolicy;
