import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import featureDataOne from "../../data/ikep/feature-one.json";
import featureDataTwo from "../../data/ikep/feature-two.json";

const [{ paragraph1, paragraph2, title, span }] = featureDataOne;

const FeatureSix = ({ sectionSpace }) => {
  console.log(featureDataTwo);
  return (
    <Fragment>
      <section
        id="features-section"
        className={`features-section ${sectionSpace} clearfix`}
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="6">
                <div className="feature-image-6 float-left">
                  <span
                    className="bg-image"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <img
                      src="assets/images/features/animation/6-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/ikep/phone2.png"
                      alt="image_not_found"
                    />
                  </span>
                  
                  <span
                    className="shape-image-1"
                    data-aos="fade-left"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/6-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-2"
                    data-aos="fade-right"
                    data-aos-delay="650"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-3"
                    data-aos="fade-left"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                  
                </div>
              </Col>
              <Col lg="6" md="8">
                <div className="feature-content">
                  <div className="section-title mb-60">
                    <h2 className="title-text mb-0">
                      {title}
                      <span>{span}</span>
                    </h2>
                  </div>

                  <p className="mb-30">{paragraph1}</p>
                  <p className="mb-60">{paragraph2}</p>

                  <div className="btns-group ul-li clearfix">

                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="6" md="8">
                <div className="feature-content">
                  {featureDataTwo.map((featureDataTwo, key) => {
                    return (
                      <div key={key} className="section-title mb-30">

                        <h2 className="title-text mb-0">
                          {featureDataTwo.title}
                          <span>{featureDataTwo.span}</span>
                        </h2>
                      </div>
                    );
                  })}

                  {featureDataTwo.map((featureDataTwo, key) => {
                    return (
                      <p key={key} className="mb-30">
                        {featureDataTwo.paragraph1}
                      </p>
                    );
                  })}

                  <div className="info-list ul-li-block mb-45 clearfix">
                    <ul className="clearfix">
                      {featureDataTwo.map((featureDataTwo) => featureDataTwo.details.map((detail, key) => {
                            return (
                              <li key={key}>
                                <small className="icon">
                                  <i className="flaticon-checked"></i>
                                </small>
                                <span className="info-text">
                                  {detail}
                                </span>
                              </li>
                            );
                          }))
                        }
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="5" md="6">
                <div className="feature-image-6 float-right">
                  <span
                    className="bg-image"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <img
                      src="assets/images/features/animation/6-img-1.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="phone-image"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <img
                      src="assets/images/ikep/phone3.png"
                      alt="image_not_found"
                    />
                  </span>
                  
                  <span
                    className="shape-image-1"
                    data-aos="fade-left"
                    data-aos-delay="600"
                  >
                    <img
                      src="assets/images/features/animation/6-img-2.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-2"
                    data-aos="fade-right"
                    data-aos-delay="650"
                  >
                    <img
                      src="assets/images/features/animation/6-img-3.png"
                      alt="image_not_found"
                    />
                  </span>
                  <span
                    className="shape-image-3"
                    data-aos="fade-left"
                    data-aos-delay="700"
                  >
                    <img
                      src="assets/images/features/animation/6-img-4.png"
                      alt="image_not_found"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default FeatureSix;
